<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable max-width="800px" persistent>
      <v-card>
        <v-card-title>{{ $t('med.medicalcertificate') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px;">
          <v-layout row wrap justify-center>
            <v-flex xs12 lg12 class="pa-4">
              
              <v-layout class="text-right">
                <div v-if="statusmultiplemed === false" class="d-flex align-center">
                  <!-- ปุ่มเลือกไฟล์ที่ยังไม่ได้นำเข้า -->           
                  <v-btn
                    class="white--text"
                    small
                    rounded
                    :color="color.theme"                      
                    v-if="!checkedListHospital"
                    @click="selectAll()"
                  >
                    {{ $t('med.selectfile') }}
                  </v-btn>
                </div>
                <div v-else>
                  <!-- ปุ่มปิดเมนู -->
                  <v-btn
                    outlined
                    :color="color.theme"
                    fab
                    small
                    class="ma-1 pa-0"
                    v-if="!checkedListHospital"
                    @click="clearMultipleSelect()"
                    @mouseover="showClose = true"
                    @mouseleave="showClose = false"
                  >
                    <v-icon dark>clear</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                      style="z-index:10000;bottom:-45px;left:-20px;"
                      :value="showClose"
                      :content="$t('med.close')"
                      transition="slide-y-transition"
                    ></v-badge>
                    <v-badge v-else
                      style="z-index:10000;bottom:-45px;left:-30px;"
                      :value="showClose"
                      :content="$t('med.close')"
                      transition="slide-y-transition"
                    ></v-badge>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <!-- ปุ่มย้อนกลับ -->
                <v-btn
                  icon
                  fab
                  small
                  text
                  @click="btnBack()"
                  :color="color.theme"
                  v-if="!checkedListHospital"
                  @mouseover="back = true"
                  @mouseleave="back = false"
                >
                  <v-icon>keyboard_backspace</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-50px;"
                    :value="back"
                    :content="$t('balloon.back')"
                    transition="slide-y-transition"              
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="back"
                    :content="$t('balloon.back')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
              </v-layout>
              <br>

              <!-- mobile -->
              <div v-if="resolutionScreen <= 500">
                <div v-if="rootfile.length !== 0">
                  <!-- hospital -->
                  <v-list class="mb-6 elevation-1 rounded-lg" v-if="checkedListHospital">
                    <template v-for="(item, i) in rootfile">
                      <v-list-item :key="i.hospital" style="cursor: pointer;" @dblclick="onSelectHospital(item.file_list)">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.hospital_name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu nudge-left="56">
                            <template v-slot:activator="{ on }" >
                              <v-btn class="elevation-0" :color="color.theme" icon fab small v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <!-- ปุ่มเปิด -->
                              <v-list-item @click="onSelectHospital(item.file_list)">
                                <v-list-item-icon>
                                  <v-icon>mdi-folder-open</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ $t('currentRClick.open') }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider
                        v-if="i < rootfile.length - 1"
                        :key="i.hospital"
                        class="mx-4"
                        style="border-color: #F5F5F5;"
                      ></v-divider>
                    </template>
                  </v-list>
                  <!-- med -->
                  <v-list two-line class="mb-6 elevation-1 rounded-lg" v-else>
                    <template v-for="(item, i) in dtMed">
                      <v-list-item :key="i.med" style="cursor: pointer;">
                        <v-checkbox
                          v-show="statusmultiplemed"
                          v-model="filemultiplemed"
                          :value="item"
                        />
                        <v-list-item-content>
                          <v-list-item-title v-text="item.filename"></v-list-item-title>
                          <v-layout>
                            <v-icon color="green" small v-if="item.entry_status === 'Y'">mdi-checkbox-marked-circle-outline</v-icon>
                            <v-icon color="red" small v-else>mdi-close-circle-outline</v-icon>
                            <v-list-item-subtitle v-text="item.entry_status === 'Y' ? 'นำเข้าแล้ว' : 'ยังไม่ได้นำเข้า'"></v-list-item-subtitle>
                          </v-layout>
                        </v-list-item-content>
                        <v-list-item-action  v-if="statusmultiplemed === false">
                          <v-menu nudge-left="72">
                            <template v-slot:activator="{ on }" >
                              <v-btn class="elevation-0" :color="color.theme" icon fab small v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <!-- ปุ่มนำเข้า -->
                              <v-list-item @click="openDialogImportFileMed = true">
                                <v-list-item-icon>
                                  <v-icon>mdi-application-import</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ $t('med.importfilemed') }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider
                        v-if="i < dtMed.length - 1"
                        :key="i.med"
                        class="mx-4"
                        style="border-color: #F5F5F5;"
                      ></v-divider>
                    </template>
                  </v-list>
                </div>
                <v-list v-else class="elevation-1 rounded-lg">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

              <!-- desktop -->
              <div v-else>                
                <!-- hospital -->
                <v-data-table
                  v-if="checkedListHospital"
                  :headers="headersHospital"
                  :items="rootfile"
                  :single-select="false"
                  item-key="file_id"
                  :no-data-text="$t('tablefile.empty')"
                  :hide-default-footer="true"
                  class="elevation-0"
                >                  
                  <template v-slot:[`header.hospital_name`]="{ header }">
                    <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:item="props">
                    <tr
                      @dblclick="onSelectHospital(props.item.file_list)"
                      style="cursor: pointer;"
                    >
                      <td width="100%">
                        {{ props.item.hospital_name }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- med -->
                <v-data-table
                  v-else
                  v-model="filemultiplemed"
                  :headers="headersMed"
                  :items="dtMed"
                  :single-select="false"
                  item-key="file_id"
                  :no-data-text="$t('tablefile.empty')"
                  :hide-default-footer="true"
                  :show-select="statusmultiplemed"
                  class="elevation-0"
                >                  
                  <template v-slot:[`header.filename`]="{ header }">
                    <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:[`header.document_date`]="{ header }">
                    <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:[`header.entry_status`]="{ header }">
                    <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:[`header.entry_dtm`]="{ header }">
                    <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:item="props">
                    <tr style="cursor: pointer;">                      
                      <td class="text-center" v-show="statusmultiplemed">
                        <v-checkbox
                          v-model="filemultiplemed"
                          :value="props.item"
                        />
                      </td>
                      <td width="25%">
                        <div style="whiteSpace: nowrap; overflow: hidden; textOverflow: ellipsis; width: 300px">
                          {{ props.item.filename }}
                        </div>
                      </td>
                      <td width="20%" class="text-center">
                        {{ props.item.document_date }}
                      </td>
                      <td width="25%" class="text-center">
                        <!-- {{ props.item.entry_status }} -->
                        <v-icon color="green" v-if="props.item.entry_status === 'Y'">mdi-checkbox-marked-circle-outline</v-icon>
                        <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                      </td>
                      <td width="25%" class="text-center">
                        {{ props.item.entry_dtm }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>                
              </div>      
            </v-flex>
          </v-layout>
          <div class="text-center" v-if="loaddataprogress === true">
            <v-progress-circular
              :size="50"
              :color="color.theme"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout v-if="!checkedListHospital" class="d-flex align-center">
            <v-btn
              class="white--text"
              :color="color.theme"
              :disabled="filemultiplemed.length === 0"
              @click="openDialogImportFileMed = true"
            >
              {{ $t('med.importfilemed') }}
            </v-btn>
          </v-layout>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="clearData()">
            {{ $t('med.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogImportFileMed
      :show="openDialogImportFileMed"
      :count="filemultiplemed.length || count"
      @closedialog="openDialogImportFileMed = false"
    ></dialogImportFileMed>

  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import dialogImportFileMed from "../optional/dialog-importfilemed";
import CryptoJS from "crypto-js";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show"],
  components: {
    dialogImportFileMed,
  },
  data: function() {
    return {
      rootfile: [],
      dtMed: [],
      checkedListHospital: true,
      back: false,
      openDialogImportFileMed: false,
      loaddataprogress: false,
      statusmultiplemed: false,
      filemultiplemed: [],
      showSelectFile: false,
      showClose: false,
      count: 1,
      headersHospital: [
        {
          text: "med.hospitalname",
          align: "left",
          value: "hospital_name",
          width: "100%",
          sortable: false,
        },
      ],
      headersMed: [
        {
          text: "med.filename",
          align: "center",
          value: "filename",
          width: "25%",
          sortable: false,
        },
        {
          text: "med.document_date",
          align: "center",
          value: "document_date",
          width: "20%",
          sortable: false,
        },
        {
          text: "med.entry_status",
          align: "center",
          value: "entry_status",
          width: "25%",
          sortable: false,
        },
        {
          text: "med.entry_dtm",
          align: "center",
          value: "entry_dtm",
          width: "25%",
          sortable: false,
        }, 
      ]
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.getDataMedicalCertificate();
        }
        return this.show;
      },
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
  },
  methods: {
    async getDataMedicalCertificate() {
      this.loaddataprogress = true;
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      let payload = {
        oneid_user_id: user_id
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/get/all_file", payload, {
            headers: { Authorization: auth.code } 
          }
        )
        .then((response) => {
          console.log("response", response);
          this.rootfile = [];
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            this.rootfile = response.data.data;
            console.log("this.rootfile", this.rootfile);
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          console.log('err', error);
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
        });
        
    },
    onSelectHospital(file_list) {
      this.checkedListHospital = false;
      this.dtMed = [];
      for (var i = 0; i < file_list.length; i++) {
        let datamed = {};
        let datetime = file_list[i].entry_dtm;
        datamed["document_date"] = file_list[i].document_date;
        datamed["entry_dtm"] = datetime === "" 
          ? "-" : `${datetime.slice(0,4)}-${datetime.slice(4,6)}-${datetime.slice(6,8)} 
                   ${datetime.slice(8,10)}:${datetime.slice(10,12)}:${datetime.slice(12,14)}`;
        datamed["entry_status"] = file_list[i].entry_status;
        datamed["file_id"] = file_list[i].file_id;
        datamed["filename"] = file_list[i].filename;
        datamed["hospital_name"] = file_list[i].hospital_name;
        this.dtMed.push(datamed);
      }
      console.log('dtMed', this.dtMed);
    },    
    btnBack() {
      this.checkedListHospital = true;
      this.back = false;
      this.clearMultipleSelect();
    },
    selectAll() {
      this.statusmultiplemed = true;
      this.showSelectFile = false;
      this.filemultiplemed = this.dtMed.filter((obj) => obj.entry_status === 'N')
      console.log("this.filemultiplemed", this.filemultiplemed)
    },
    clearMultipleSelect() {
      this.statusmultiplemed = false;
      this.showClose = false;
      this.filemultiplemed.splice(0, this.filemultiplemed.length);
    },
    clearData() {
      this.$emit('closedialog');
      this.checkedListHospital = true;
      this.clearMultipleSelect();
    },
  },
};
</script>
<style>
.drag-selector {
  padding: 10px;
}
.drag-selector__item {
  align-items: center;
  /* padding: -1px; */
  /* height: 40px;
  padding-left: 16px;
  vertical-align: middle;
  display: flex;
  margin: 5px; */
  /* background-color: rebeccapurple; */
}
#borderradius {
  border-radius: 15px;
}
#borderradius-img {
  border-radius: 15px 15px 0px 0px;
}
#borderradius-word {
  border-radius: 0px 0px 15px 15px;
}
#borderradius_btn {
  border-radius: 5px;
}
</style>
