<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="700px" persistent>
      <v-card>
        <v-card-text style="height: 150px;" class="d-flex align-center">
          <v-layout row wrap justify-center>
            <v-flex xs12 lg12>
              <v-card-title class="justify-center text-center">
                {{ $t('med.messageimportfilemed1') }} {{ count }} {{ $t('med.messageimportfilemed2') }}
              </v-card-title>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="color.theme" dark>
            {{ $t('med.confirm') }}
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="clearData()">
            {{ $t('med.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "count"],
  data: function() {
    return {
      
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {    
    // async multipleimportfile() {
    //   // if (this.filemultiplemed.length < 1) {
    //   //   Toast.fire({
    //   //     type: "warning",
    //   //     title: this.$t("resendfile.msg.nofile"),
    //   //   });
    //   // } else {
    //     this.openDialogImportFileMed = true;
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultiplemed.length; i++) {
    //       filedata.push(this.filemultiplemed[i]["id"]);
    //       console.log("++",this.filemultiplemed[i]);
    //     }
    //       console.log(filedata);
    //       let payload = {
    //         inbox_id: filedata
    //       };
    //       let auth = await gbfGenerate.generateToken();
    //       await this.axios
    //         .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/resend/inbox", payload, {
    //           headers: { Authorization: auth.code },
    //         })
    //         .then((res) => {
    //           console.log("res_multipleresendfile", res);
    //           if (res.data.status === "OK") {
    //             Toast.fire({
    //               icon: "success",
    //               title: "Resend File Success",
    //             });
    //             this.clearMultipleSelect();
    //             this.getDataResend();
    //           } else {
    //             Toast.fire({
    //               icon: "error",
    //               title: "ไม่สามารถส่งไฟล์อีกครั้งได้",
    //             });
    //           }
    //           this.clearMultipleSelect();
    //         })
    //         .catch((error) => {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("resendfile.msg.apierror"),
    //           });
    //           console.log(error);
    //         });
    //   // }
    // },
    clearData() {
      this.$emit('closedialog');
    },
  },
};
</script>
